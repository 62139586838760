<template>
  <div class="zone">
    <TJDetailTitle :titleData="'销量统计_报告'" />
    <functionBox 
    totalText=""
    :titleData="{permission_flag:'Sales',nowID:3}"
      :searhLoading="loading"
      @searchBtn="searchBtn"
    >
    <template>
      <li>
        聚合方式：
        <el-select
          v-model="value"
          placeholder="请选择聚合方式"
          size="small"
          @change="changeWay"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </li>
      <li>
        <el-select
            v-model="optionsArrValue"
            placeholder="请选择"
            size="small"
            class="box"
          >
            <el-option
              v-for="item in optionsArr"
              :key="item.testing_unique"
              :label="item.testing_name"
              :value="item.testing_unique"
            ></el-option>
          </el-select>
      </li>
      <li>
        <div class="timePicker">
          <div class="calendarone">
            <el-radio-group v-model="radio" size="small">
              <el-radio-button label="年"></el-radio-button>
              <el-radio-button label="月"></el-radio-button>
              <!-- <el-radio-button label="日"></el-radio-button> -->
            </el-radio-group>
          </div>
          <div class="calendar">
            <div v-if="radio === '年'" class="el_calendar_year">
              <el-date-picker
                v-model="start_stats_year"
                type="year"
                :placeholder="startPlaceholder"
                :format="format"
                :value-format="formatValue"
                size="small"
                @change="changeYearData"
              ></el-date-picker>
              ～
              <el-date-picker
                v-model="end_stats_year"
                type="year"
                :placeholder="endPlaceholder"
                :format="format"
                :value-format="formatValue"
                size="small"
                @change="changeYearData"
              ></el-date-picker>
            </div>
            <el-date-picker
              v-else
              ref="datedate"
              size="small"
              v-model="dateMonthValue"
              :format="format"
              :value-format="formatValue"
              :type="type"
              :start-placeholder="startPlaceholder"
              :end-placeholder="endPlaceholder"
              class="Calendar"
            />
          </div>
        </div>
        
      </li>
    </template>
  </functionBox>    
    <!-- <div class="indent">
      <div class="indentone">
        <div class="province cursor" @click="toSales">省份</div>
        <div class="line"></div>
        <div class="spital cursor" @click="toAgency">经销商</div>
        <div class="line"></div>
        <div class="left cursor">报告</div>
        <div class="line"></div>
        <div class="Device cursor" @click="toTurnover">日成交量</div>
      </div>
      <div class="below">
        <div class="city">
          聚合方式：
          <el-select
            v-model="value"
            placeholder="请选择聚合方式"
            size="small"
            @change="changeWay"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-select
            v-model="optionsArrValue"
            placeholder="请选择"
            size="small"
            class="box"
          >
            <el-option
              v-for="item in optionsArr"
              :key="item.testing_unique"
              :label="item.testing_name"
              :value="item.testing_unique"
            ></el-option>
          </el-select>
        </div>

        <div class="calendarone">
          <div class="title">时间：</div>
          <el-radio-group v-model="radio" size="small">
            <el-radio-button label="年"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="calendar">
          <div v-if="radio === '年'" class="el_calendar_year">
            <el-date-picker
              v-model="start_stats_year"
              type="year"
              :placeholder="startPlaceholder"
              :format="format"
              :value-format="formatValue"
              size="small"
              @change="changeYearData"
            ></el-date-picker>
            ～
            <el-date-picker
              v-model="end_stats_year"
              type="year"
              :placeholder="endPlaceholder"
              :format="format"
              :value-format="formatValue"
              size="small"
              @change="changeYearData"
            ></el-date-picker>
          </div>
          <el-date-picker
            v-else
            ref="datedate"
            size="small"
            v-model="dateMonthValue"
            :format="format"
            :value-format="formatValue"
            :type="type"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            class="Calendar"
          />
        </div>
        <div class="seachone">
          <el-button type="primary" size="small" @click="searchBtn">
            搜索
          </el-button>
        </div>
      </div>
    </div> -->
    <div class="sheet">
      <!-- 平台 -->
      <el-table
        :data="tableData"
        stripe
        height="700"
        v-loading="loading"
        :summary-method="getSummaries"
        v-show="value == 0"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :label="item.label"
          align="center"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div v-if="item.value == 'orders_sum'">
              {{ $tool.moneyFormatter(Number(scope.row[item.value]), 2) }}
            </div>
            <div v-else-if="item.value == 'amount'">
              {{ $tool.moneyFormatter(Number(scope.row[item.value]), 0) }}
            </div>
            <div v-else>{{ scope.row[item.value] }}</div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 试剂 -->
      <el-table
        :data="tableData"
        height="700"
        v-loading="loading"
        v-show="value == 1"
        show-summary
        :summary-method="getSummaries"
        stripe
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div
              label=""
              align="center"
              v-for="(i, o) of props.row.children"
              :key="o"
              class="innerBox"
            >
              <div v-for="(q, w) of i" :key="w">
                {{ q }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="reagent2platform_id"
          label="聚合方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orders_sum"
          label="金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="人份数"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import functionBox from './components/functionBox.vue'

export default {
  components: { TJDetailTitle,functionBox },
  name: 'Volume',
  data() {
    return {
      options: [
        {
          value: '0',
          label: '平台',
        },
        {
          value: '1',
          label: '试剂',
        },
      ], //聚合方式
      value: '0', //聚合方式-值
      optionsArr: [], //聚合方式-对于数据
      optionsArrValue: '', //聚合方式-对于数据-值

      radio: '年', //时间节点
      start_stats_year: '2022', //开始年份
      end_stats_year: '2022', //结束年份
      dateMonthValue: '', //月份和日期选择
      page: 1,
      size: 10,
      total: 0,

      loading: false, //加载动画
      tableData: [],

      tableHeaderPt: [
        {
          label: '聚合方式',
          value: 'reagent2platform_id',
        },
        {
          label: '金额',
          value: 'orders_sum',
        },
        {
          label: '人份数',
          value: 'amount',
        },
      ],
      tableHeaderSj: [
        {
          label: '聚合方式',
          value: 'reagent_class_id',
        },
        {
          label: '金额',
          value: 'orders_sum',
        },
        {
          label: '人份数',
          value: 'amount',
        },
      ],
      tableHeader: [],

    }
  },
  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },
    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
    format() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy/MM',
        日: 'yyyy/MM/dd',
      }
      return arr[this.radio]
    },
    formatValue() {
      const arr = {
        年: 'yyyy',
        月: 'yyyyMM',
        日: 'yyyyMMdd',
      }
      return arr[this.radio]
    },
  },
  watch: {
    type() {
      this.start_stats_year = ''
      this.end_stats_year = ''
      this.dateMonthValue = ''
    },
  },
  mounted() {
    this.tableHeader = this.tableHeaderPt
    this.trade_by_platformFun()
    this.reagent_platformFun();
  },
  methods: {
    // 监听聚合方式下拉框变化
    changeWay(value) {
      this.optionsArrValue = ''
      switch (value) {
        case '0': //平台
          this.tableHeader = this.tableHeaderPt
          this.reagent_platformFun()
          break
        case '1': //试剂
          this.tableHeader = this.tableHeaderSj
          this.reagent_class_type_dictFun()
          break
      }
    },
    // 平台
    reagent_platformFun() {
      api.reagent_platform({}).then((res) => {
        let data = res.data.data
        this.searchBtn()
        this.optionsArr = data.map((e) => {
          return {
            testing_unique: e.reagent2platform_id,
            testing_name: e.reagent2platform_id,
          }
        })
      })
    },
    //试剂
    reagent_class_type_dictFun() {
      api.reagent_class_type_dict({}).then((res) => {
        let data = res.data
        this.searchBtn()
        this.optionsArr = data.map((e) => {
          return {
            testing_unique: e.reagent_class_type_id,
            testing_name: e.reagent_class_type_id,
          }
        })
      })
    },

    // 搜索
    searchBtn() {
      this.page = 1
      switch (this.value) {
        case '0': //平台
          this.trade_by_platformFun()
          break
        case '1': //试剂
          this.testing_daily_tradeFun()
          break
      }
    },

    // 销量统计报告-根据平台聚合
    trade_by_platformFun() {
      this.loading = true
      let param = {
        platform_unique: this.optionsArrValue, //平台英文名

        page: this.page,
        size: this.size,
        ...this.filterQuery(),
      }
      api.trade_by_platform(param).then((res) => {
        let data = res.data.data
        this.tableData = data
        this.total = res.data.count
        this.loading = false
      })
    },

    // 销量统计-根据检测项目聚合
    testing_daily_tradeFun() {
      this.loading = true
      let param = {
        reagent_class_type: this.optionsArrValue, //检测项目标识
        page: this.page,
        size: this.size,
        ...this.filterQuery(),
      }
      api
        .testing_daily_trade(param)
        .then((res) => {
          let data = res.data.data.map((element, index) => {
            let comment = element.comment.map((e, i) => {
              return {
                reagent_class_id: e.reagent_class_id,
                orders_sum: this.$tool.moneyFormatter(Number(e.orders_sum), 2),
                amount: this.$tool.moneyFormatter(Number(e.amount), 0),
              }
            })
            return {
              reagent_class_id: element.reagent_class_type,
              orders_sum: this.$tool.moneyFormatter(Number(element.orders_total),2),
              amount:this.$tool.moneyFormatter( Number(element.amount_total),0),
              children: comment,
            }
          })
          this.tableData = data
          this.total = res.data.count
          
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },

    // 跳转页面携带参数处理
    filterQuery() {
      let param = {}
      if (this.radio == '年') {
        this.start_stats_year != ''
          ? (param.start_stats_year = this.start_stats_year)
          : ''
        this.end_stats_year != ''
          ? (param.end_stats_year = this.end_stats_year)
          : ''
      } else if (this.radio == '月') {
        this.dateMonthValue != null
          ? (param.start_stats_month = this.dateMonthValue[0])
          : ''

        this.dateMonthValue != null
          ? (param.end_stats_month = this.dateMonthValue[1])
          : ''
      } else if (this.radio == '日') {
        this.dateMonthValue != null
          ? (param.start_stats_day = this.dateMonthValue[0])
          : ''

        this.dateMonthValue != null
          ? (param.end_stats_day = this.dateMonthValue[1])
          : ''
      }
      return param
    },

    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },

    // 翻页
    handleCurrentChange(val) {
      this.page = val
      this.trade_by_platformFun()
    },

    toSales() {
      this.$router.push({ path: '/Sales' })
    },
    toAgency() {
      this.$router.push({ path: '/Agency' })
    },
    toTurnover() {
      this.$router.push({ path: '/Turnover' })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' '
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
  },
}
</script>

<style lang="scss" scoped>
.city {
  .box {
    margin-left: 10px;
  }
}
::v-deep .el-date-editor .el-range__icon {
  display: none;
}

.indent {
  height: px(121);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
}
.indentone {
  width: 100%;
  height: px(56);
  display: flex;
  border-bottom: 1px solid #d0d7e0;
  margin-bottom: px(15);
  .cursor {
    cursor: pointer;
  }
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
  margin-right: px(20);
}
.spital {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.Device {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.province {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.line {
  width: 1px;
  height: 13px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: px(16);
  margin-right: px(20);
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.below {
 // height: px(36);
  display: flex;
}
.calendar {
  margin-left: px(10);
  .el_calendar_year {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep .el-date-editor.el-input,
    ::v-deep .el-date-editor.el-input__inner {
      width: 120px !important;
    }
  }
}
.cityProvince {
  margin-left: px(10);
}
::v-deep .cityProvince .el-input--small .el-input__inner {
  width: px(220);
}
.timePicker {
  display: flex;
  .calendarone {
    display: flex;
    align-items: center;
  }
  .calendar {
    margin-left: px(5);
    display: flex;
    align-items: center;
  }
}
.seachone {
  margin-left: px(10);
}
.sheet {
  min-height: px(793);
  border-radius: px(10);

  background-color: #fff;
  margin-bottom: px(23);
  .innerBox {
    display: flex;
    margin-left: px(220);
    margin-bottom: px(20);
    div {
      text-align: center;
      width: 30%;
      margin-left: px(30);
    }
  }
}
.series {
  display: flex;
  margin-left: -12px;
}
.series img {
  width: px(36);
  height: px(36);
}
.bd8 {
  width: 1px;
  height: 8px;
  border-radius: 0.5px 0.5px 0.5px 0.5px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: 10px;
}
.combination {
  font-size: px(16);
  color: #333333;
  margin-top: px(10);
}
::v-deep .el-table__footer-wrapper .cell {
  font-size: px(14);
  font-weight: bold;
  color: #006ed0ff;
}
</style>
